var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.visible)?_c('div',{staticClass:"preview-layer",on:{"click":_vm.closePreviewModal}},[_c('div',{staticClass:"preview-view"},[_c('RealPhonePreview',{on:{"click":function($event){$event.stopPropagation();}},scopedSlots:_vm._u([{key:"preview",fn:function(){return [_c('div',{staticClass:"meeting-room-detail"},[(_vm.meetingRoomInfo && _vm.meetingRoomInfo.photos)?_c('div',{staticClass:"meeting-room-photos"},[_c('el-carousel',{attrs:{"height":"220px"}},_vm._l((_vm.meetingRoomInfo.photos),function(item,imgN){return _c('el-carousel-item',{key:imgN},[_c('img',{attrs:{"src":item,"alt":""}})])}),1)],1):_vm._e(),_c('div',{staticClass:"meeting-room-info"},[_c('div',{staticClass:"meeting-room-info-item"},[_c('p',[_c('img',{attrs:{"src":require("../../assets/meeting-room/meeting-room-name.png")}}),_vm._v(" "+_vm._s(_vm.meetingRoomInfo.name)+" ")])]),_c('div',{staticClass:"meeting-room-info-item"},[_c('p',[_c('img',{attrs:{"src":require("../../assets/meeting-room/meeting-room-people.png")}}),_vm._v(" "+_vm._s(_vm.meetingRoomInfo.capacity)+" ")])]),(
                                _vm.meetingRoomInfo.type ===
                                _vm.MEETING_ROOM_TYPE.PRIVATE
                            )?_c('div',{staticClass:"meeting-room-info-item"},[_c('p',[_c('img',{attrs:{"src":require("../../assets/meeting-room/company.png")}}),_vm._v(" "+_vm._s(_vm.company)+" "),_c('span',[_vm._v(_vm._s(_vm.meetingRoomInfo.companyName))])]),_c('p',{staticClass:"tips"},[_vm._v(" 注：该公司私有会议室只限所属员工预约。 ")])]):_vm._e(),_c('div',{staticClass:"meeting-room-info-item"},[_c('p',[_c('img',{attrs:{"src":require("../../assets/meeting-room/meeting-room-address.png")}}),_vm._v(" "+_vm._s(_vm.meetingRoomInfo.location)+" ")])])]),(
                            _vm.meetingRoomInfo.type ===
                            _vm.MEETING_ROOM_TYPE.PUBLIC
                        )?[_c('div',{staticClass:"rule-info"},[_c('div',{staticClass:"rule-info-title"},[_vm._v("预约须知")]),_c('div',{staticClass:"rule-info-content"},[_c('p',[_vm._v(" 1.预约，不收任何费用。（先使用，后付款） ")]),_c('p',[_vm._v(" 2.请按时前往会议室，在“我的订单”，操作“扫码开门”。 ")]),_c('p',[_vm._v(" 3.预约后到达开始时间15分钟还未扫码开门，则视为已过期，需要重新预约。 ")])])]),_c('div',{staticClass:"rule-info"},[_c('div',{staticClass:"rule-info-title"},[_vm._v("计费规则")]),_c('div',{staticClass:"rule-info-content"},[_c('p',[_vm._v(" 1.按实际使用时间支付费用，扫码开门开始计费，扫码结算停止计费。 ")]),_c('p',[_vm._v(" 2.使用时间未满30分钟，按30分钟计算；超过30分钟未满1小时，按1小时计算。 ")])])])]:[_c('div',{staticClass:"rule-info"},[_c('div',{staticClass:"rule-info-title"},[_vm._v("预约须知")]),_c('div',{staticClass:"rule-info-content"},[_c('p',[_vm._v(" 1.请按时前往会议室，在“我的订单”，操作“扫码开门”。 ")]),_c('p',[_vm._v(" 2.预约后到达开始时间15分钟还未扫码开门，则视为已过期，需要重新预约。 ")])])])],_c('div',{staticClass:"meeting-room-footer"},[(
                                _vm.meetingRoomInfo.type ===
                                _vm.MEETING_ROOM_TYPE.PUBLIC
                            )?[_c('div',{staticClass:"meeting-room-photo"},[_c('img',{attrs:{"src":require("../../assets/meeting-room/phone.png")}}),_vm._v(" 咨询 ")])]:_vm._e(),_c('button',[(
                                    _vm.meetingRoomInfo.type ===
                                    _vm.MEETING_ROOM_TYPE.PUBLIC
                                )?[_vm._v(" ¥"+_vm._s(_vm.meetingRoomInfo.price)+"/小时 ")]:_vm._e(),_vm._v(" 立即预约 ")],2)],2)],2)]},proxy:true}],null,false,2935422517)}),_vm._t("btnGroup")],2)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }