<template>
    <div class="preview-layer" v-if="visible" @click="closePreviewModal">
        <div class="preview-view">
            <RealPhonePreview @click.stop>
                <template v-slot:preview>
                    <div class="meeting-room-detail">
                        <div
                            class="meeting-room-photos"
                            v-if="meetingRoomInfo && meetingRoomInfo.photos"
                        >
                            <el-carousel height="220px">
                                <el-carousel-item
                                    v-for="(
                                        item, imgN
                                    ) in meetingRoomInfo.photos"
                                    :key="imgN"
                                >
                                    <img :src="item" alt="" />
                                </el-carousel-item>
                            </el-carousel>
                        </div>

                        <div class="meeting-room-info">
                            <div class="meeting-room-info-item">
                                <p>
                                    <img
                                        src="../../assets/meeting-room/meeting-room-name.png"
                                    />
                                    {{ meetingRoomInfo.name }}
                                </p>
                            </div>
                            <div class="meeting-room-info-item">
                                <p>
                                    <img
                                        src="../../assets/meeting-room/meeting-room-people.png"
                                    />
                                    {{ meetingRoomInfo.capacity }}
                                </p>
                            </div>
                            <div
                                class="meeting-room-info-item"
                                v-if="
                                    meetingRoomInfo.type ===
                                    MEETING_ROOM_TYPE.PRIVATE
                                "
                            >
                                <p>
                                    <img
                                        src="../../assets/meeting-room/company.png"
                                    />
                                    {{ company }}
                                    <span >{{meetingRoomInfo.companyName}}</span>
                                </p>
                                <p class="tips">
                                    注：该公司私有会议室只限所属员工预约。
                                </p>
                            </div>
                            <div class="meeting-room-info-item">
                                <p>
                                    <img
                                        src="../../assets/meeting-room/meeting-room-address.png"
                                    />
                                    {{ meetingRoomInfo.location }}
                                </p>
                            </div>
                        </div>

                        <!-- 共享 -->
                        <template
                            v-if="
                                meetingRoomInfo.type ===
                                MEETING_ROOM_TYPE.PUBLIC
                            "
                        >
                            <div class="rule-info">
                                <div class="rule-info-title">预约须知</div>
                                <div class="rule-info-content">
                                    <p>
                                        1.预约，不收任何费用。（先使用，后付款）
                                    </p>
                                    <p>
                                        2.请按时前往会议室，在“我的订单”，操作“扫码开门”。
                                    </p>
                                    <p>
                                        3.预约后到达开始时间15分钟还未扫码开门，则视为已过期，需要重新预约。
                                    </p>
                                </div>
                            </div>
                            <div class="rule-info">
                                <div class="rule-info-title">计费规则</div>
                                <div class="rule-info-content">
                                    <p>
                                        1.按实际使用时间支付费用，扫码开门开始计费，扫码结算停止计费。
                                    </p>
                                    <p>
                                        2.使用时间未满30分钟，按30分钟计算；超过30分钟未满1小时，按1小时计算。
                                    </p>
                                </div>
                            </div>
                        </template>
                        <template v-else>
                            <!-- 私有 -->
                            <div class="rule-info">
                                <div class="rule-info-title">预约须知</div>
                                <div class="rule-info-content">
                                    <p>
                                        1.请按时前往会议室，在“我的订单”，操作“扫码开门”。
                                    </p>
                                    <p>
                                        2.预约后到达开始时间15分钟还未扫码开门，则视为已过期，需要重新预约。
                                    </p>
                                </div>
                            </div>
                        </template>

                        <div class="meeting-room-footer">
                            <template
                                v-if="
                                    meetingRoomInfo.type ===
                                    MEETING_ROOM_TYPE.PUBLIC
                                "
                            >
                                <div class="meeting-room-photo">
                                    <img
                                        src="../../assets/meeting-room/phone.png"
                                    />
                                    咨询
                                </div>
                            </template>
                            <button>
                                <template
                                    v-if="
                                        meetingRoomInfo.type ===
                                        MEETING_ROOM_TYPE.PUBLIC
                                    "
                                >
                                    ¥{{ meetingRoomInfo.price }}/小时
                                </template>
                                立即预约
                            </button>
                        </div>
                    </div>
                </template>
            </RealPhonePreview>
            <slot name="btnGroup"></slot>
        </div>
    </div>
</template>

<script>
import RealPhonePreview from "@/components/RealPhonePreview";

const MEETING_ROOM_TYPE = {
    PUBLIC: 2,
    PRIVATE: 1,
};
export default {
    name: "meetingRoomPreview",
    components: {
        RealPhonePreview,
    },
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        company: String,
        meetingRoomInfo: Object,
    },
    data() {
        return {
            MEETING_ROOM_TYPE,
        };
    },
    methods: {
        // 关闭弹窗
        closePreviewModal() {
            this.$emit("closeModal");
        },
    },
    mounted() {},
};
</script>
<style lang="stylus" scoped>
.meeting-room-detail
    width 100%
    // height 100%
    background #F0F0F0
    position relative
    // padding-bottom 32px
    .meeting-room-photos
        width 100%
        height 220px
        img
            width 100%
            height 100%
    .meeting-room-info
        margin-top 8px
        background #ffffff
        &-item
            padding 12px 14px
            font-size 14px
            .tag
                background #FFF0F0
                color #FF5A5A
                font-size 9.6px
                width 32px
                height 16px
                border-radius 2.5px
                float right
            img
                width 14px
                height 14px
                margin-right 8px
            p
                margin-bottom 0
            .tips
                color #999
                margin-top 7.2px
                font-size 12px
                text-indent 22px
            &:not(:last-of-type)
                border-bottom 1px solid #f0f0f0
    .rule-info
        background #ffffff
        margin-top 8px
        padding 12px 14px
        &-title
            font-size 14px
            position relative
            text-indent 8px
            margin-bottom 8px
            &::before
                position absolute
                left 0
                top 50%
                transform translateY(-50%)
                content ' '
                width 3.5px
                height 10px
                background #1DAFFF
        &-content
            font-size 12px
            p
                margin-bottom 2px
                line-height 16px
    .meeting-room-footer
        display flex
        flex-flow row nowrap
        align-items center
        position sticky
        bottom 0
        left 0
        width 100%
        cursor pointer
        box-shadow 3px -3px 4px 0px rgba(0, 0, 0, 0.1)
        height 32px
        .meeting-room-photo
            width 120px
            background #fff
            display flex
            flex-flow row nowrap
            align-items center
            font-size 14.4px
            height 100%
            justify-content center
            img
                width 11px
                margin-right 2px
        button
            flex 1 1 auto
            background #FF5A5A
            height 100%
            color #fff
            font-size 14.4px
            text-align center
            border none
</style>
